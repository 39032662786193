/**
 * If overflow is clipped, a scroll-bar should be added to see the rest of the content
 *
 * 如果溢出框，则应该提供滚动机制
 */
.overflow-auto {
  overflow: auto !important;
}

/**
 * The overflow is clipped, and the rest of the content will be invisible
 *
 * 裁剪内容，不提供滚动机制
 */
.overflow-hidden {
  overflow: hidden !important;
}
