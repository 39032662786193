@global-footer-prefix: ~'.global-footer';

@{global-footer-prefix} {
  display: block;
  margin: 48px 0 24px;
  padding: 0 16px;
  text-align: center;

  &__links {
    margin-bottom: 8px;

    &-item {
      display: inline-block;
      color: @text-color-secondary;
      transition: all 0.3s;

      &:not(:last-child) {
        margin-right: 40px;
      }

      &:hover {
        color: @text-color;
      }
    }
  }

  &__copyright {
    font-size: @font-size-base;
    color: @text-color-secondary;
  }
}

@import './rtl.less';
