.header-dropdown {
  background-color: @popover-background;
  border-radius: 4px;
  box-shadow: @shadow-1-down;
}

@media screen and (max-width: @screen-xs) {
  .header-dropdown {
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
}
