.@{ant-prefix}-card {
  margin-bottom: @md;
}

/**
 * Set `nz-card` content area without spacing
 *
 * 设置 `nz-card` 内容区域无间距
 */
.@{ant-prefix}-card__body-nopadding {
  .@{ant-prefix}-card-body {
    padding: 0 !important;
  }
}
