/**
 * Set element not float
 *
 * 设置元素不浮动
 */
.float-none {
  float: none !important;
}

/**
 * Set the element to float to the left
 *
 * 设置元素向左浮动
 */
.float-left {
  float: left !important;
}

/**
 * Set the element to float to the right
 *
 * 设置元素向右浮动
 */
.float-right {
  float: right !important;
}
