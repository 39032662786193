@{tag-select-prefix}-rtl {
  direction: rtl;
  margin-right: -8px;
  margin-left: 0;

  &__has-expand {
    padding-right: 0 !important;
    padding-left: 50px !important;
  }

  @{tag-select-prefix} {
    .@{ant-prefix}-tag {
      margin-right: 0;
      margin-left: 24px;
    }

    &__trigger {
      right: initial;
      left: 0;

      &-icon {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}
