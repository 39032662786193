/**
 * Vertical align type: Aligns the baseline of the element with the baseline of its parent
 *
 * 垂直对齐方式：元素放置在父元素的基线上
 */
.align-baseline {
  vertical-align: baseline !important;
}

/**
 * Vertical align type: Aligns the top of the element and its descendants with the top of the entire line
 *
 * 垂直对齐方式：把元素的顶端与行中最高元素的顶端对齐
 */
.align-top {
  vertical-align: top !important;
}

/**
 * Vertical align type: Aligns the middle of the element with the baseline plus half the x-height of the parent
 *
 * 垂直对齐方式：把此元素放置在父元素的中部
 */
.align-middle {
  vertical-align: middle !important;
}

/**
 * Vertical align type: Aligns the bottom of the element and its descendants with the bottom of the entire line
 *
 * 垂直对齐方式：把元素的顶端与行中最低的元素的顶端对齐
 */
.align-bottom {
  vertical-align: bottom !important;
}

/**
 * Vertical align type: Aligns the bottom of the element with the bottom of the parent element's font
 *
 * 垂直对齐方式：把元素的底端与父元素字体的底端对齐
 */
.align-text-bottom {
  vertical-align: text-bottom !important;
}

/**
 * Vertical align type: Aligns the top of the element with the top of the parent element's font
 *
 * 垂直对齐方式：把元素的顶端与父元素字体的顶端对齐
 */
.align-text-top {
  vertical-align: text-top !important;
}
