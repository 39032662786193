@result-prefix: ~'.result';

@{result-prefix} {
  display: block;
  width: 72%;
  margin: 0 auto;
  text-align: center;

  &__icon {
    margin-bottom: 24px;
    font-size: 72px;
    line-height: 72px;

    &-success {
      color: @success-color;
    }

    &-error {
      color: @error-color;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: @heading-color;
  }

  &__desc {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
    color: @text-color-secondary;
  }

  &__extra {
    padding: 24px 40px;
    text-align: left;
    background: @result-extra-bg;
    border-radius: @border-radius-sm;

    @media screen and (max-width: @screen-xs) {
      padding: 18px 20px;
    }
  }

  &__actions {
    margin-top: 32px;

    button:not(:last-child) {
      margin-right: 8px;
    }
  }
  .@{ant-prefix}-steps .@{ant-prefix}-steps-head,
  .@{ant-prefix}-steps .@{ant-prefix}-steps-title {
    background: @result-extra-bg;
  }

  @media screen and (max-width: @screen-xs) {
    width: 100%;
  }
}

@import './rtl.less';
