@import '../../style/themes/index';
@import '../../style/mixins/index';

@graph-stroke-color: @primary-3;
@graph-focused-color: @primary-color;
@graph-stroke-width-base: 1px;

@graph-node-background-color: @primary-1;

@graph-prefix-cls: ~'nz-graph';

.@{graph-prefix-cls} {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: @component-background;

  &-nodes {
    .@{graph-prefix-cls}-node {
      &-rect {
        fill: transparent;
        stroke: @graph-stroke-color;
        stroke-width: @graph-stroke-width-base;

        &:hover {
          stroke: @graph-focused-color;
        }
      }
    }
  }

  &-edges {
    .@{graph-prefix-cls}-edge {
      path {
        fill: none;
        stroke: @graph-stroke-color;
        stroke-linecap: butt;
        stroke-width: @graph-stroke-width-base;

        &:hover {
          stroke: @graph-focused-color;
        }
      }

      &-text {
        font-size: @font-size-sm;
        fill: fade(@text-color, 85%);
      }
    }
  }

  &-edge-marker {
    color: @graph-stroke-color;
    fill: @graph-stroke-color;
  }

  &-minimap {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: @component-background;
    border: @border-width-base @border-style-base @border-color-base;
    transition: opacity 0.3s linear;
    pointer-events: auto;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    canvas {
      border: 1px solid #999;
    }

    rect {
      cursor: move;
      filter: url('#minimapDropShadow');
      fill: @component-background;
      stroke: @text-color-secondary;
      stroke-width: @border-width-base;
      fill-opacity: 0;
    }

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .buffer,
    .viewport {
      display: block;
      height: 100%;
    }
  }
}
