@onboarding-prefix: ~'.onboarding';

@{onboarding-prefix} {
  + .cdk-overlay-container {
    z-index: @onboarding-zindex;
  }

  &__mask {
    position: fixed;
    z-index: @onboarding-zindex - 1;
    inset: 0;
    background-color: @onboarding-mask-background-color;
  }

  &__item {
    position: absolute;
  }

  &__light {
    pointer-events: none;
    position: absolute;
    z-index: @onboarding-zindex;
    background-color: @onboarding-light-background-color;
    animation: @onboarding-light-ant;

    &-el {
      z-index: @onboarding-zindex + 1 !important;
    }

    &-ant {
      transition: @onboarding-changing-ant;
    }
  }

  &__footer {
    margin-top: 8px;
  }

  &__total {
    color: @text-color;
  }

  @keyframes onboardingAnt {
    0%,
    33% {
      box-shadow: color(@onboarding-light-ant-color) 0 0 0 2px, @onboarding-light-ant-color 0 0 0;
    }

    66%,
    100% {
      box-shadow: @onboarding-light-ant-color 0 0 0 2px, fade(@onboarding-light-ant-color, 1%) 0 0 0 10px;
    }
  }
}

@import './rtl.less';
