@avatar-list-prefix: ~'.avatar-list';

@{avatar-list-prefix} {
  display: inline-block;

  &__wrap {
    display: inline-block;
    margin-left: 8px;
    padding: 0;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    width: @avatar-size-base;
    height: @avatar-size-base;
    margin-left: -8px;
    font-size: @font-size-base;

    .@{ant-prefix}-avatar {
      cursor: pointer;
      border: 1px solid #fff;
    }
  }

  &__large {
    width: @avatar-size-lg;
    height: @avatar-size-lg;
  }

  &__small {
    width: @avatar-size-sm;
    height: @avatar-size-sm;
  }

  &__mini {
    width: @avatar-list-mini-wh;
    height: @avatar-list-mini-wh;

    .@{ant-prefix}-avatar {
      width: @avatar-list-mini-wh;
      height: @avatar-list-mini-wh;
      line-height: @avatar-list-mini-wh;

      &-string {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

@import './rtl.less';
