@{alain-default-prefix}__fixed {
  @{alain-default-prefix} {
    &__header {
      position: fixed;
      top: 0;
      left: 0;
    }

    &__aside {
      position: fixed;
    }
  }
}

@{alain-default-prefix}__fixed:not(@{alain-default-prefix}__hide-header) {
  @{alain-default-prefix} {
    &__content {
      margin-top: @alain-default-header-hg;
    }
  }
}
