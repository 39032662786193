@reuse-tab-prefix: ~'.reuse-tab';

@{reuse-tab-prefix} {
  user-select: none;
  display: block;
  background-color: @reuse-tab-bg;
  outline: none;
  .@{ant-prefix}-tabs {
    &-nav .@{ant-prefix}-tabs-tab {
      @{reuse-tab-prefix}__op {
        position: absolute;
        display: none;
        margin: 0;
        font-size: 10px;
        color: @text-color;
      }

      &:hover {
        @{reuse-tab-prefix}__op {
          display: block;
        }
      }
      @{reuse-tab-prefix}__name {
        &-width {
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
        }
      }
    }
    > .@{ant-prefix}-tabs-nav {
      margin: 0;
    }
  }

  &__line {
    padding: @reuse-tab-padding;
    border-bottom: 1px solid @reuse-tab-border-color;
    .@{ant-prefix}-tabs {
      &-nav {
        &::before {
          border-bottom: none;
        }
        .@{ant-prefix}-tabs-tab {
          padding: 0;
          @{reuse-tab-prefix}__op {
            top: 12px;
            right: 2px;
          }
          @{reuse-tab-prefix}__name {
            display: inline-block;
            padding: 8px 20px;
          }
        }
      }
    }
  }

  &__card {
    padding-top: @reuse-tab-padding;
    .@{ant-prefix}-tabs-nav-container {
      padding: 0 8px;
    }
    .@{ant-prefix}-tabs-nav .@{ant-prefix}-tabs-tab {
      @{reuse-tab-prefix}__op {
        top: 4px;
        right: 4px;
      }
    }
  }

  &__disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

@{reuse-tab-prefix}__cm {
  .@{ant-prefix}-menu {
    border: 1px solid #e9e9e9;
  }
}

@import './rtl.less';
