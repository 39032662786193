.@{ant-prefix}-form-item-label {
  em {
    color: @grey-6;
  }
}

.@{ant-prefix}-form-item-control {
  em {
    margin-left: @layout-gutter;
  }
}

[dir='rtl'] {
  .@{ant-prefix}-form-item-control {
    em {
      margin-right: @layout-gutter;
      margin-left: inherit;
    }
  }
}
