@exception-prefix: ~'.exception';

@{exception-prefix} {
  display: flex;
  align-items: center;
  height: 100%;

  &__img-block {
    zoom: 1;
    flex: 0 0 62.5%;
    width: 62.5%;
    padding-right: 152px;

    &::before,
    &::after {
      content: ' ';
      display: table;
    }

    &::after {
      clear: both;
      height: 0;
      font-size: 0;
      visibility: hidden;
    }
  }

  &__img {
    float: right;
    width: 100%;
    max-width: 430px;
    height: 360px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
  }

  &__cont {
    flex: auto;

    &-title {
      margin-bottom: 24px;
      font-size: 72px;
      font-weight: 600;
      line-height: 72px;
      color: #434e59;
    }

    &-desc {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 28px;
      color: @text-color-secondary;
    }

    &-actions {
      .@{ant-prefix}-btn:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: @screen-xl) {
  @{exception-prefix} {
    &__img-block {
      padding-right: 88px;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  @{exception-prefix} {
    display: block;
    text-align: center;

    &__img-block {
      margin: 0 auto 24px;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: @screen-xs) {
  @{exception-prefix} {
    &__img-block {
      overflow: hidden;
      margin-bottom: -24px;
    }
  }
}

@import './rtl.less';
