/**
 * Icon size `28px`
 *
 * 图标大小 `28px`
 */
.icon-sm {
  font-size: @icon-sm !important;
}

/**
 * Icon size `56px`
 *
 * 图标大小 `56px`
 */
.icon-md {
  font-size: @icon-md !important;
}

/**
 * Icon size `84px`
 *
 * 图标大小 `84px`
 */
.icon-lg {
  font-size: @icon-lg !important;
}

/**
 * Icon size `112px`
 *
 * 图标大小 `112px`
 */
.icon-xl {
  font-size: @icon-xl !important;
}

/**
 * Icon size `140px`
 *
 * 图标大小 `140px`
 */
.icon-xxl {
  font-size: @icon-xxl !important;
}
