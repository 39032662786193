// st
// --
@st-btn-disabled-color: fade(@black, 25%);
@st-title-optional-color: fade(@black, 35%);
@st-resizable-handle-width: 1px;
@st-resizable-handle-height: 60%;
@st-resizable-handle-color: @border-color-base;
@st-no-column-height: 100px;

// sv
// --
@sv-label-color: fade(@black, 50%);
@sv-label-optional-color: fade(@black, 35%);
@sv-detail-color: @text-color;
@sv-type-primary-color: @primary-color;
@sv-type-success-color: @success-color;
@sv-type-danger-color: @error-color;
@sv-type-warning-color: @warning-color;
@sv-bottom: @layout-gutter * 2;
@sv-default-text: '-';
@sv-unit-margin: 8px;
@sv-unit-color: @grey-7;
@sv-optional-margin: 2px;
@sv-value-prefix-unit-color: @sv-label-color;
@sv-value-prefix-unit-font-size: 12px;
@sv-value-default-value-font-size: 18px;
@sv-value-large-value-font-size: 24px;
@sv-value-small-value-font-size: 14px;
@sv-value-mulit-padding: 8px;
@sv-value-mulit-content: '/';
@sv-value-mulit-content-width: 6px;

// se
// --
@se-label-optional-color: fade(@black, 35%);
@se-compact-bottom: @layout-gutter;
@se-optional-margin: 2px;

// avatar-list
// --
@avatar-list-mini-wh: 20px;

// error-collect
// --
@error-collect-color: #f5222d;

// footer-toolbar
// --
@footer-toolbar-height: 56px;
@footer-toolbar-box-shadow: 0 -1px 2px fade(@black, 3%);
@footer-toolbar-bg: #fff;
@footer-toolbar-border-top: 1px solid @border-color-split;
@footer-toolbar-padding: 0 24px;

// page-header
// --
@page-header-bg: #fff;
@page-header-padding: 16px 32px 0 32px;
@page-header-wide: 1200px;
@page-header-title-sub-color: fade(@heading-color, 40%);

// quick-menu
// --
@quick-menu-border-width: 1px;
@quick-menu-ctrl-hw: 50px;
@quick-menu-border-color: #fff;
@quick-menu-bg: #fff;

// result
// --
@result-extra-bg: #fafafa;

// reuse-tab
// --
@reuse-tab-height: 52px;
@reuse-tab-bg: #fff;
@reuse-tab-padding: 8px;
@reuse-tab-border-color: #d9d9d9;

// down-file
// --
// - `hidden` 隐藏状态（默认）
// - `disabled` 禁用状态
@down-file-not-support-behavior: 'hidden';

// loading
// --
@loading-zindex: 9999;
@loading-backdrop-bg: fade(@black, 15%);
@loading-icon-color: @primary-color;
@loading-text-color: @primary-color;

// onboarding
// --
@onboarding-mask-background-color: fade(@black, 50%);
@onboarding-light-background-color: fade(@white, 90%);
@onboarding-light-ant: 2500ms cubic-bezier(0.55, 0.05, 0.6, 0.2) 0s infinite normal none running onboardingAnt;
@onboarding-light-ant-color: rgb(255, 255, 255);
@onboarding-changing-ant: all 0.3s ease-out;
@onboarding-zindex: 99999;

// tag-select
// --
@tag-select-margin: 16px;
@tag-select-item-margin-right: 24px;

// cell
// --
@cell-default-color: @text-color-secondary;
@cell-large: 18px;
@cell-small: 12px;
@cell-unit-color: @text-color-secondary;
@cell-unit-font-size: 12px;
@cell-img-space: 4px;
@cell-link-disabled-color: @text-color;
