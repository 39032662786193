@down-file-prefix: ~'.down-file';

@{down-file-prefix} {
  &__not-support {
    // @down-file-not-support-behavior
    .down-file-not-support-behavior-mixin(@value) when (@value = 'disabled') {
      pointer-events: none;
    }
    .down-file-not-support-behavior-mixin(@value) when (@value = 'hidden') {
      display: none;
    }
    /* stylelint-disable-next-line order/order */
    .down-file-not-support-behavior-mixin(@down-file-not-support-behavior);
  }
}
