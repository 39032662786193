@tag-select-prefix: ~'.tag-select';
@tag-select-height: @tag-line-height + 2px; // 2px is top & bottom border width

@{tag-select-prefix} {
  user-select: none;
  position: relative;
  overflow: hidden;
  display: block;
  max-height: @tag-select-height + @tag-select-margin;
  margin-top: -@tag-select-margin;
  margin-left: -8px;
  line-height: @line-height-base;
  transition: all 0.3s;

  &__expanded {
    max-height: 200px;
    transition: all 0.3s;
  }

  &__has-expand {
    padding-right: 50px;
  }
  .@{ant-prefix}-tag {
    margin-top: @tag-select-margin;
    margin-right: @tag-select-item-margin-right;
  }

  &__trigger {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    margin-right: 0 !important;
    color: @primary-color;

    > .anticon {
      margin-left: 2px;
      font-size: 8px;
      transition: transform .24s;
    }
  }
}

@import './rtl.less';
