@{full-content-prefix} {
  &__body {
    .alain-default__content {
      router-outlet + * {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .alain-default__content-title {
      margin-left: -24px;
    }
  }

  &__opened {
    .alain-default__header,
    .alain-default__aside,
    reuse-tab {
      display: none !important;
    }

    .alain-default__content {
      margin: 24px !important;
    }
  }

  &__hidden-title {
    .alain-default__content-title,
    .page-header {
      display: none !important;
    }
  }
}
