@media (max-width: @screen-xs-max) {
  .hidden-xs {
    /**
     * `576px` hidden below
     *
     * `576px` 以下隐藏
     */
    display: none !important;
  }
}

@media (max-width: @screen-sm-max) {
  .hidden-sm {
    /**
     * `768px` hidden below
     *
     * `768px` 以下隐藏
     */
    display: none !important;
  }
}

@media (max-width: @screen-md-max) {
  .hidden-md {
    /**
     * `992px` hidden below
     *
     * `992px` 以下隐藏
     */
    display: none !important;
  }
}

@media (max-width: @screen-lg-max) {
  .hidden-lg {
    /**
     * `1200px` hidden below
     *
     * `1200px` 以下隐藏
     */
    display: none !important;
  }
}

@media (max-width: @screen-xl-max) {
  .hidden-xl {
    /**
     * `1600px` hidden below
     *
     * `1600px` 以下隐藏
     */
    display: none !important;
  }
}

@media (min-width: @mobile-min) {
  .hidden-pc {
    /**
     * Hidden on the PC, default: above `768px`
     *
     * PC端隐藏，默认：`768px` 以上
     */
    display: none !important;
  }
}

@media (max-width: @mobile-max) {
  .hidden-mobile {
    /**
     * Hidden on the mobile, default: below `768px`
     *
     * 移动端隐藏，默认：`768px` 以下
     */
    display: none !important;
  }
}
