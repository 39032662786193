/**
  * Hide step icon
  *
  * 隐藏步数操作区
  */
.@{ant-prefix}-input-number__hide-step {
  .@{ant-prefix}-input-number-handler-wrap {
    visibility: hidden;
  }
}
