@loading-prefix: ~'.loading';

@{loading-prefix} {
  &-backdrop {
    z-index: @loading-zindex;
    opacity: 1;
    background-color: @loading-backdrop-bg;
  }

  &-default {
    position: fixed;
    z-index: @loading-zindex + 1;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
      margin-bottom: 8px;
      font-size: 24px;
      color: @loading-icon-color;
    }

    &__text {
      color: @loading-text-color;
    }

    &__custom {
      width: 64px;
      height: 64px;
      fill: rgb(255, 255, 255);
    }
  }
}

@import './rtl.less';
