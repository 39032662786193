.alain-default-widget-app-icons-mixin(@enabled) when(@enabled=true) {
  .app-icons {
    padding: @layout-gutter * 2;
    .@{ant-prefix}-col-6 {
      cursor: pointer;
      padding: (@layout-gutter * 2) 0;
      text-align: center;
      border-radius: 4px;
      transition: background-color 300ms;

      &:hover {
        background-color: @item-hover-bg;
      }
    }

    i {
      padding: 15px;
      font-size: 22px;
      border-radius: 50%;
    }

    small {
      display: block;
      padding-top: 4px;
      font-size: 14px;
      color: #9c9c9c;
    }
  }
}
.alain-default-widget-app-icons-mixin(@alain-default-widget-app-icons-enabled);
