@pdf-prefix: ~'.pdf';

@{pdf-prefix} {
  &-container {
    position: absolute;
    overflow-x: auto;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
}
