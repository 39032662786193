@se-prefix: ~'.se';

@{se-prefix} {
  &__title {
    float: none;
    clear: both;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: @line-height-base;
    color: @heading-color;
  }

  &__item {
    transition: none;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &-empty {
      &::before {
        content: '';
        display: inline-block;
      }
    }

    +.@{ant-prefix}-divider {
      margin: 0 0 @form-item-margin-bottom;
    }
  }

  &__label {
    display: table-cell;
    flex: 0 0 auto;
    width: initial;
    text-align: right;
    white-space: nowrap;

    &-optional {
      margin-left: @se-optional-margin;
      color: @se-label-optional-color;

      &-no-text {
        margin-left: 0;
      }

      >.anticon {
        margin-left: @se-optional-margin;
      }
    }
  }

  &__control {
    flex: 1 0 0;
    width: 100%;
  }

  &__compact {

    >@{se-prefix}__title,
    >@{se-prefix}__item {
      margin-bottom: @se-compact-bottom;

      +.@{ant-prefix}-divider {
        margin: 0 0 @se-compact-bottom;
      }
    }

    .@{ant-prefix}-form-item-extra {
      display: none;
    }
  }

  &__line {
    padding-bottom: @layout-gutter;
    border-bottom: 1px dashed @border-color-split;
  }

  &__hide-label,
  &__inline,
  &__vertical {
    @{se-prefix}__nolabel {
      display: none !important;
    }
  }

  &__horizontal {
    >@{se-prefix}__item {
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      @{se-prefix}__label {
        display: flex;
        justify-content: flex-end;

        &-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .@{ant-prefix}-form-item {
        &-required::before {
          line-height: inherit;
        }

        &-label>label::after {
          margin-left: 4px;
        }
      }
    }
  }

  &__vertical {
    >@{se-prefix}__item {
      display: block;
      width: 100%;
    }
  }

  &__inline {

    .@{ant-prefix}-form-item-label,
    @{se-prefix}__control {
      display: inline-block;
      width: initial !important;
      text-align: left;
      vertical-align: top;
    }

    >@{se-prefix}__item {
      margin-right: 16px;

      &[col='1'] {
        flex-basis: auto;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__no-colon::after {
    content: ' ' !important;
  }
}

@media (max-width: @mobile-max) {
  @{se-prefix} {
    &__horizontal {
      >@{se-prefix}__item {
        display: block;

        .@{ant-prefix}-form-item-label {
          width: 100% !important;
          text-align: left;
        }

        @{se-prefix}__label {
          justify-content: normal;
        }
      }
    }

    &__horizontal,
    &__vertical {

      @{se-prefix}__label,
      @{se-prefix}__control {
        width: 100% !important;
      }
    }
  }
}
