@st-prefix: ~'.st';

@{st-prefix} {
  display: block;

  &__p-left {
    .@{ant-prefix}-table-pagination {
      float: none;
      display: block;
      text-align: left;
    }
  }

  &__p-center {
    .@{ant-prefix}-table-pagination {
      float: none;
      display: block;
      text-align: center;
    }
  }
  // 修复按钮block
  &__btn {
    &-sub {
      .@{ant-prefix}-menu-title-content {
        width: 100%;
      }
      @{st-prefix}__btn-text {
        width: 100%;
        color: inherit;
        display: block;
      }
    }

    &-disabled {
      @{st-prefix}__btn-text {
        pointer-events: none;
        color: @st-btn-disabled-color;
      }
    }
  }
  // 调整表头宽度
  .nz-resizable {
    &-handle {
      display: flex;
      align-items: center;
      justify-content: center;

      > i {
        width: @st-resizable-handle-width;
        height: @st-resizable-handle-height;
        background: @st-resizable-handle-color;
      }
    }

    &-preview {
      border-width: 0;
      border-right-width: 1px;
    }
    // 当存在排序时，增加与排序之间的间隔
    &.@{ant-prefix}-table-column-has-sorters {
      .nz-resizable-handle {
        margin-right: 8px;
      }
    }
  }
  // 清除页码底部间距，在大部分列表中会包裹 `nz-card` 导致底部间距过大，若有需求可自行使用 `mb-md` 来调整
  .@{ant-prefix}-table ~ nz-pagination > .@{ant-prefix}-pagination {
    margin-bottom: 0;
  }

  &__filter {
    &-keyword {
      padding: @layout-gutter;
    }

    &-date {
      .@{ant-prefix}-picker-panel-container {
        box-shadow: none;
        .@{ant-prefix}-picker-panel {
          border: none;
        }
      }
    }
  }
  // Title
  &__has-filter {
    @{st-prefix}__filter {
      display: inline-flex;
    }
  }

  &__head {
    &-optional,
    &-tip {
      margin-left: 2px;
      color: @st-title-optional-color;
    }
  }
  // 修复自动定义图标无法高亮问题
  .@{ant-prefix}-table-thead > tr > th .@{ant-prefix}-table-filter-selected.@{ant-prefix}-table-filter-icon {
    color: @primary-color;
  }
  // 修复全选与快捷的间距
  &__checkall-selection {
    display: inline-block;
    margin-left: 4px;
  }
  // 修复 `st` 会导致内嵌表格的BUG
  .@{ant-prefix}-table-tbody > tr {
    .@{ant-prefix}-table-wrapper:only-child {
      // 修复 https://github.com/ng-alain/ng-alain/issues/1619
      .ant-table-tbody > tr:last-child > td {
        border-bottom: @border-width-base @border-style-base @border-color-split;
      }
      // 额外产生边距 https://github.com/ng-alain/ng-alain/issues/1608
      .@{ant-prefix}-table {
        margin: 0;
      }
    }
  }
  // 若 `widthMode` 为 `truncate` 会导致固定列的阴影失效，其他情况不存在此情况
  // 这里使用 `text-truncate` 来区分
  // https://github.com/ng-alain/ng-alain/issues/2336
  .text-truncate.@{ant-prefix}-table {
    &-cell-fix-left-first::after,
    &-cell-fix-left-last::after {
      transform: translateX(80%);
    }

    &-cell-fix-right-first::after,
    &-cell-fix-right-last::after {
      transform: translateX(-80%);
    }
  }

  &__no-column {
    .@{ant-prefix}-table {
      height: @st-no-column-height;

      &-content {
        display: none;
      }
    }
  }

  // 修复rowClassName在固定列下无作用
  // https://github.com/ng-alain/ng-alain/issues/2383
  &__row-class {
    // 限定只有数据单元格才会默认添加该样式
    .@{ant-prefix}-table-tbody > tr > td {
      .@{ant-prefix}-table-cell-fix-left,
      .@{ant-prefix}-table-cell-fix-right {
        background: inherit;
      }
    }
  }

  @media screen and (min-width: @nz-table-rep-min-width) {
    &__width {
      &-strict {
        .ant-table-content,
        .ant-table-body {
          > table {
            table-layout: fixed !important;
            word-wrap: break-word !important;
          }
        }
      }
    }
  }
}
