@error-collect-prefix: ~'.error-collect';

@{error-collect-prefix} {
  cursor: pointer;
  padding-right: 24px;
  color: @error-collect-color;

  &__count {
    padding-left: 8px;
  }
}

@import './rtl.less';
