@full-content-prefix: ~'.full-content';

@{full-content-prefix} {
  overflow: auto;
  display: block;
  height: 100%;

  &__body {
    overflow: hidden;
  }
}
