.@{ant-prefix}-tag {
  &__plus {
    /**
      * Tag: [+] style
      *
      * 标签：【+】的样式
      */
    .@{ant-prefix}-tag {
      background: #fff;
      border-style: dashed;
    }
  }
}

/**
  * Corrected `ng-tag` list line wrapping spacing
  *
  * 纠正 `ng-tag` 列表换行间距问题
  */
.tag-wrap-list-spacing {
  margin-top: -8px;

  .@{ant-prefix}-tag {
    margin-top: 8px;
  }
}
