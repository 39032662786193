@sf-prefix: ~'.sf';

.col100() {
  flex: 0 0 100%;
  max-width: 100%;
}

@{sf-prefix} {
  display: block;

  &__optional {
    margin-left: @sf-optional-margin;
    color: @sf-optional-color;
  }
  // 固定 label
  &__fixed {
    display: flex;
    .@{ant-prefix}-form-item-control-wrapper {
      flex: 1;
    }
  }

  &__title {
    margin-bottom: 8px;
    padding: 0 16px;
    font-weight: 700;
    text-align: @sf-title-text-align;
  }
  // Object
  &__object {
    &-card {
      .@{ant-prefix}-card-body {
        padding-left: 0;
      }
      @{sf-prefix}__fixed {
        flex-wrap: wrap;
      }

      &-fold {
        .@{ant-prefix}-card-body {
          display: none;
        }
      }
    }
  }
  // Array
  &__array {
    > .@{ant-prefix}-form-item {
      margin-bottom: 8px;
    }

    &-add {
      margin-right: 16px;
    }

    &-container {
      .@{ant-prefix}-card {
        margin: 0 16px 16px 0;

        &-body {
          padding-bottom: 0;
        }
        @{sf-prefix}__array-remove {
          cursor: pointer;
          position: absolute;
          top: -16px;
          right: -16px;
          display: none;
          width: 32px;
          height: 32px;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          background: @sf-widget-array-type-card-remove-bg;
          border-radius: 50%;

          i {
            color: #fff;
          }
        }

        &:hover {
          @{sf-prefix}__array-remove {
            display: block;
          }
        }
      }
    }

    @media (max-width: @mobile-max) {
      > .@{ant-prefix}-form-item {
        > .@{ant-prefix}-form-item-label,
        > .@{ant-prefix}-form-item-control-wrapper {
          .col100();
        }
        > .@{ant-prefix}-form-item-label {
          display: flex;
          margin-bottom: 8px;
        }
      }
      // 强制所有数组宽度为 100% 状态
      &-container {
        .@{ant-prefix}-card {
          margin-right: 0;
          @{sf-prefix}__array-remove {
            right: 8px;
            display: block;
          }
        }
        > @{sf-prefix}__array-item {
          .col100();
        }
      }
    }
  }

  // upload
  .@{ant-prefix}-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  .@{ant-prefix}-upload.@{ant-prefix}-upload-drag {
    height: 180px;
  }
  // transfer
  .@{ant-prefix}-transfer {
    &-list {
      &-header {
        label {
          position: unset;
        }
      }
    }
    .@{ant-prefix}-btn + .@{ant-prefix}-btn {
      margin-left: 0;
    }
  }

  &__no-error {
    .@{ant-prefix}-form-item {
      margin-bottom: 8px;
    }
  }

  &__inline {
    @{sf-prefix}__item {
      display: inline-block;
      margin-bottom: 8px;
      vertical-align: top;
    }
    .@{ant-prefix}-form-inline {
      > @{sf-prefix}__item {
        margin-bottom: 0;
      }
      .@{ant-prefix}-select {
        min-width: @sf-inline-ant-select-min-width;
      }
    }
    @{sf-prefix} {
      // 强制所有数组宽度为 100% 状态
      &__array-container {
        > @{sf-prefix}__array-item {
          flex: initial;
          width: 100%;
          max-width: 100%;
        }
        .@{ant-prefix}-card {
          margin: 0;

          &-body {
            padding: 8px 0 0 8px;
            > @{sf-prefix}__item {
              margin-bottom: 0;
            }
          }
        }
      }

      &-btns {
        display: inline-block;
        margin-right: 0;
      }
    }

    @media (max-width: @mobile-max) {
      @{sf-prefix}__item {
        display: block;
        width: 100%;
        .@{ant-prefix}-form-item {
          display: block !important;
          width: initial !important;
          margin-right: 0;
        }
      }
      .@{ant-prefix}-form-item-label,
      .@{ant-prefix}-form-item-control {
        display: block !important;
        padding: inherit;
      }
    }
  }

  &__horizontal {
    .@{ant-prefix}-form-item-label > label {
      display: flex;
      justify-content: flex-end;
    }
    @{sf-prefix}__label-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media (max-width: @mobile-max) {
      .@{ant-prefix}-form-item-label > label {
        justify-content: flex-start;
      }
    }
  }

  &__no-colon {
    .@{ant-prefix}-form-item-label {
      label {
        &::after {
          content: ' ';
        }
      }
    }
  }

  &__compact {
    .@{ant-prefix}-form-item {
      margin-bottom: 8px;
    }
    @{sf-prefix}__array {
      &-container {
        .@{ant-prefix}-card {
          margin: 0 8px 8px 0;
        }
      }

      &-item {
        .@{ant-prefix}-card-body {
          padding: 8px;
        }
      }
    }
  }
}

@import './_issues.less';
