@alain-default-name: 'alain-default';
@alain-default-prefix: ~'.@{alain-default-name}';
@alain-default-zindex: @zindex-base;
@alain-default-ease: cubic-bezier(0.25, 0, 0.15, 1);
@alain-default-header-hg: ~'var(--@{alain-default-name}-header-hg, 64px)';
@alain-default-header-bg: @primary-color;
@alain-default-header-padding: @layout-gutter * 2;
@alain-default-header-search-enabled: true;
@alain-default-header-icon-fs: 18px;
@alain-default-header-logo-max-height: 36px;
@alain-default-header-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
@alain-default-header-nav-bg-hover: rgba(255, 255, 255, 0.2);

@alain-default-header-top-menu-item-padding: 0 16px;

@alain-default-aside-wd: 200px;
@alain-default-aside-bg: ~'var(--@{alain-default-name}-aside-bg, #fff)';
@alain-default-aside-scrollbar-width: 0;
@alain-default-aside-scrollbar-height: 0;
@alain-default-aside-scrollbar-track-color: transparent;
@alain-default-aside-scrollbar-thumb-color: transparent;

@alain-default-aside-nav-fs: 14px;
@alain-default-aside-nav-icon-width: 14px;
@alain-default-aside-nav-img-wh: 14px;
@alain-default-aside-nav-padding-top-bottom: @layout-gutter;
@alain-default-aside-nav-padding-left-right: @layout-gutter * 2;
@alain-default-aside-nav-text-color: @text-color;
@alain-default-aside-nav-text-hover-color: @primary-color;
@alain-default-aside-nav-group-text-color: @text-color-secondary;
@alain-default-aside-nav-selected-text-color: @primary-color;
@alain-default-aside-nav-selected-bg: ~'var(--@{alain-default-name}-aside-nav-selected-bg, #fcfcfc)';
@alain-default-aside-nav-depth1-padding-left: @layout-gutter * 3;
@alain-default-aside-nav-depth2-padding-left: @layout-gutter * 4;
@alain-default-aside-nav-depth3-padding-left: @layout-gutter * 5;
@alain-default-aside-nav-depth4-padding-left: @layout-gutter * 6;
@alain-default-aside-nav-depth5-padding-left: @layout-gutter * 7;

@alain-default-aside-collapsed-wd: @layout-gutter * 8;
@alain-default-aside-collapsed-nav-fs: 24px;
@alain-default-aside-collapsed-nav-img-wh: 24px;
@alain-default-aside-collapsed-padding: (@layout-gutter * 2) 0;

@alain-default-content-heading-bg: ~'var(--@{alain-default-name}-heading-bg, #fafbfc)';
@alain-default-content-heading-border: ~'var(--@{alain-default-name}-heading-border, #efe3e5)';
@alain-default-content-padding: @layout-gutter * 3;
@alain-default-content-bg: ~'var(--@{alain-default-name}-content-bg, #f5f7fa)';

@alain-default-widget-app-icons-enabled: true;
@alain-default-aside-user-enabled: true;
