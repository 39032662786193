@footer-toolbar-prefix: ~'.footer-toolbar';

@{footer-toolbar-prefix} {
  position: fixed;
  z-index: @zindex;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: @footer-toolbar-height;
  padding: @footer-toolbar-padding;
  line-height: @footer-toolbar-height;
  background: @footer-toolbar-bg;
  border-top: @footer-toolbar-border-top;
  box-shadow: @footer-toolbar-box-shadow;
  transition: all 0.3s;

  &__left {
    flex: 1;
  }
}
