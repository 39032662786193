@import '../../style/themes/index';
@import '../../style/mixins/index';

@code-editor-prefix-cls: ~'@{ant-prefix}-code-editor';

.@{code-editor-prefix-cls} {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: @component-background;

  .@{code-editor-prefix-cls}-loading {
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: @component-background;
  }

  .@{code-editor-prefix-cls}-toolkit {
    position: absolute;
    top: 20px;
    right: 160px;
    z-index: 2;
    min-width: 100px;
    height: 24px;
    text-align: right;
    background: transparent;

    i,
    span[nz-icon],
    nz-icon {
      position: relative;
      right: 4px;
      cursor: pointer;

      &:not(:last-child) {
        padding-right: 4px;
      }

      &.active {
        color: @primary-color;
      }
    }
  }
}
